<template>
  <div style="width: 100%" class="position-relative">
    <v-text-field
        class="zindex-1"
        dense outlined clearable
        placeholder="(Enter để tìm kiếm)"
        v-model="search"
        @keyup.enter="getAllStudent(search)"
        @focus="handleFocusSearch"
        @blur="handleBlurSearch"
    >
    </v-text-field>
    <v-card
        v-if="showListStudentItem"
        class="position-absolute zindex-2"
        style="margin-top: -2.3em; width: 100%; max-width: 100%"
        tile
        :loading="cardSearchLoading"
    >
      <template v-for="(student, iStudent) in studentItems">
        <v-list-item :key="iStudent" class="cursor-pointer" @click="setStudentObjEmit(student)">
          <v-list-item-title>{{student.phone}} - {{student.name}}</v-list-item-title>
        </v-list-item>
      </template>
      <v-list-item v-if="!studentItems.length">
        <v-list-item-title>(no data)</v-list-item-title>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";

export default {
  name: "StudentSearch",
  data() {
    return {
      showListStudentItem: false,
      search: '',
      studentItems: [],
      cardSearchLoading: false,
    };
  },
  computed: {},

  created() {

  },
  methods: {
    async getAllStudent(query) {
      query = query ?? '';
      if (query) {
        let vm = this;
        try {
          vm.cardSearchLoading = true;
          let res = await ApiService.get(`prep-app/student/list-shorten?query=${query}`);
          if ([200, 202].includes(res.status)) {
            vm.studentItems = res.data.data;
            for (let i = 0; i < vm.studentItems.length; i++) {
              vm.studentItems[i].label = vm.studentItems[i].phone + " ( " + vm.studentItems[i].name + " )";
            }
            vm.cardSearchLoading = false;
          }
        } catch (error) {
          vm.cardSearchLoading = false;
          console.log(error);
        }
      }

    },
    setStudentObjEmit(student) {
      this.$emit('setStudentObj', student);
      this.search = '';
      this.showListStudentItem = false;
    },
    handleFocusSearch() {
      this.showListStudentItem = true;
    },
    handleBlurSearch() {
      setTimeout(() => {
        this.showListStudentItem = false;
      }, 200);
    }

  },
}
</script>

<style scoped>

</style>